import { IToolbarItem, IToolbarItemGroup } from "@amzn/aws-euc-ui";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import { useMemo } from "react";
import {
    getApplicationFlag,
    getMobileDeviceFlag,
} from "../../../utils/toolbarItemUtils";
import { useApplications } from "./useApplications";
import { ToolbarMode } from "../../../constants/Toolbar";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { useWindows } from "./useWindows";
import { useFileToolbarItem } from "./useFileToolbarItem";
import { useClipboardToolbarItem } from "./useClipboardToolbarItem";
import { useDualMonitor } from "./useDualMonitor";
import { useFullScreenToolbarItem } from "./useFullScreenToolbarItem";
import { useMicrophone } from "./useMicrophone";
import { useMicrophoneWithDropdown } from "./useMicrophoneWithDropdown";
import { useCameraWithDropdown } from "./useCameraWithDropdown";
import { useFunctionKeysToolbarItem } from "./useFunctionKeysToolbarItem";
import { usePreferencesToolbarItem } from "./preference/usePreferencesToolbarItem";
import { useProfileToolbarItem } from "./user/useProfileToolbarItem";
import { useNotificationToolbarItem } from "./notification/useNotificationToolbarItem";
import { isFeatureEnabled } from "../../../configurations";
import { getHiddenToolbarItemsFromConfig } from "../../../utils/toolbarSettingsUtils";
import { HiddenToolbarItems } from "../../../enums/toolbarConfig";
/**
 * Filter out `undefined`, `null`, false, 0 items from the list
 */
const getConditionalArray = (...items) => items.filter((item) => !!item);

export const useToolbarItems = (
    appStreamSDK: AppStreamSDK,
    toolbarState: ToolbarMode
) => {
    const hiddenToolbarItems = getHiddenToolbarItemsFromConfig();
    const showFunctionKeysToolbarItem = useToolbarPreferenceStore(
        (store) => store.showFunctionKeysToolbarItem
    );
    const showApplicationToolbarItem = useMemo(() => getApplicationFlag(), []);

    const applicationToolbarItem = useApplications(appStreamSDK, toolbarState);
    const windowsToolbarItem = useWindows(appStreamSDK, toolbarState);
    const fileToolbarItem = useFileToolbarItem(appStreamSDK, toolbarState);
    const clipboardToolbarItem = useClipboardToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const dualMonitorToolbarItem = useDualMonitor(appStreamSDK);
    const fullScreenToolbarItem = useFullScreenToolbarItem(appStreamSDK);
    const microphoneToolbarItem = useMicrophone(appStreamSDK);
    const microphoneWithDropdownToolbarItem = useMicrophoneWithDropdown(
        appStreamSDK
    );
    const cameraWithDropdownButtonToolbarItem = useCameraWithDropdown(
        appStreamSDK
    );
    const functionKeysToolbarItem = useFunctionKeysToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const preferenceToolbarItem = usePreferencesToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const profileToolbarItem = useProfileToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const notificationItem = useNotificationToolbarItem(toolbarState);

    const isMediaDeviceSelectorFeatureEnabled = isFeatureEnabled(
        "mediaDeviceSelector"
    );

    const isDisableWebcamFeatureEnabled = isFeatureEnabled("disableWebcam");

    const isMobileFlagOn = useMemo(() => getMobileDeviceFlag(), []);

    // We will be migrating from disableWebcam flag to toolbar config - hidden toolbar items for hidding webcam functionality.
    // LOWA-24444: Eventually we should remove the disableWebcam flag when users are migrated to the new toolbar config.
    const showWebcamToolbarItem =
        isMediaDeviceSelectorFeatureEnabled &&
        !isDisableWebcamFeatureEnabled &&
        !hiddenToolbarItems.includes(HiddenToolbarItems.WEBCAM);

    const showWindowsToolbarItem = !hiddenToolbarItems.includes(
        HiddenToolbarItems.WINDOWS
    );

    const showDualMonitorToolbarItem = !hiddenToolbarItems.includes(
        HiddenToolbarItems.DUAL_MONITOR
    );

    const showFullScreenToolbarItem = !hiddenToolbarItems.includes(
        HiddenToolbarItems.FULL_SCREEN
    );

    const showMicrophoneToolbarItem =
        !isMediaDeviceSelectorFeatureEnabled &&
        !hiddenToolbarItems.includes(HiddenToolbarItems.MICROPHONE);

    const showMicrophoneWithDropdownToolbarItem =
        isMediaDeviceSelectorFeatureEnabled &&
        !hiddenToolbarItems.includes(HiddenToolbarItems.MICROPHONE);

    if (toolbarState === ToolbarMode.Docked) {
        return getConditionalArray(
            showApplicationToolbarItem && applicationToolbarItem,
            showWindowsToolbarItem && windowsToolbarItem,
            appStreamSDK.isFileExplorerEnabled() && fileToolbarItem,
            appStreamSDK.isClipboardEnabled() && clipboardToolbarItem,
            !isMobileFlagOn &&
                showDualMonitorToolbarItem &&
                dualMonitorToolbarItem,
            !isMobileFlagOn &&
                showFullScreenToolbarItem &&
                fullScreenToolbarItem,
            showMicrophoneToolbarItem && microphoneToolbarItem,
            showMicrophoneWithDropdownToolbarItem &&
                microphoneWithDropdownToolbarItem,
            showWebcamToolbarItem && cameraWithDropdownButtonToolbarItem,
            showFunctionKeysToolbarItem && functionKeysToolbarItem,
            preferenceToolbarItem,
            notificationItem,
            profileToolbarItem
        );
    }
    return getConditionalArray(
        showApplicationToolbarItem && applicationToolbarItem,
        showWindowsToolbarItem && windowsToolbarItem,
        appStreamSDK.isFileExplorerEnabled() && fileToolbarItem,
        appStreamSDK.isClipboardEnabled() && clipboardToolbarItem,
        showDualMonitorToolbarItem && dualMonitorToolbarItem,
        showFullScreenToolbarItem && fullScreenToolbarItem,
        showMicrophoneToolbarItem && microphoneToolbarItem,
        showMicrophoneWithDropdownToolbarItem &&
            microphoneWithDropdownToolbarItem,
        showWebcamToolbarItem && cameraWithDropdownButtonToolbarItem,
        showFunctionKeysToolbarItem && functionKeysToolbarItem,
        preferenceToolbarItem,
        notificationItem,
        profileToolbarItem
    );
};
