import { cookieManagerInstance } from "../cookies";
import {
    CustomCookieIdentifier,
    DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS,
} from "../constants/ShortbreadConfig";
import {
    ToolbarSettingsCookie,
    ToolbarSettingsCookieSchema,
} from "../types/toolbarSettings";
import { Mode } from "@cloudscape-design/global-styles";
import { AppStreamEmbedConstant } from "../constants";
import log, { TOOLBAR_METRIC_NAME } from "../logging";
import { ToolbarMode } from "../constants/Toolbar";
import { getToolbarConfig, isFeatureEnabled } from "../configurations";
import { isDisplayResolutionUnderMaxResolution } from "./displayResolutionUtils";

/**
 * Get the toolbar settings cookie.
 * @returns ToolbarSettingsCookie if a valid cookie is found,
 * else empty ToolbarSettingsCookie
 */
const getToolbarSettingsCookie = (): ToolbarSettingsCookie => {
    const tempCookie = JSON.parse(
        cookieManagerInstance.getCookieStorage(
            CustomCookieIdentifier.TOOLBAR_SETTINGS.name
        ) ?? "{}"
    );
    // Parse the cookie to make sure all data is valid before returning
    const parsedCookie = ToolbarSettingsCookieSchema.safeParse(tempCookie);
    if (parsedCookie.success) {
        log.publishCounterMetric(
            TOOLBAR_METRIC_NAME.GET_TOOLBAR_SETTINGS_COOKIE_SUCCESS
        );
        return parsedCookie.data;
    } else {
        log.publishCounterMetric(
            TOOLBAR_METRIC_NAME.GET_TOOLBAR_SETTINGS_COOKIE_ERROR
        );
        return {} as ToolbarSettingsCookie;
    }
};

/**
 * Validate and set the toolbar settings cookie.
 * @param cookie containing toolbar settings
 * @returns true if successful, false otherwise
 */
const setToolbarSettingsCookie = (cookie: ToolbarSettingsCookie): boolean => {
    const parsedCookie = ToolbarSettingsCookieSchema.safeParse(cookie);
    if (parsedCookie.success) {
        cookieManagerInstance.setCookieStorage(
            CustomCookieIdentifier.TOOLBAR_SETTINGS.name,
            JSON.stringify(cookie),
            DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS
        );
        log.publishCounterMetric(
            TOOLBAR_METRIC_NAME.SET_TOOLBAR_SETTINGS_COOKIE_SUCCESS
        );
        return true;
    } else {
        log.publishCounterMetric(
            TOOLBAR_METRIC_NAME.SET_TOOLBAR_SETTINGS_COOKIE_ERROR
        );
        return false;
    }
};

/**
 * Get visual mode field from toolbar settings cookie
 * @returns visual mode (Light or Dark) or undefined if not exist
 */
export const getVisualModeFromConfigOrCookie = (): Mode | undefined => {
    const toolbarConfiguration = getToolbarConfig();
    if (toolbarConfiguration.visualMode) {
        return toolbarConfiguration.visualMode as Mode;
    }
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieVisualMode = toolbarSettingsCookie.visualMode;
        log.publishCounterMetric(
            cookieVisualMode
                ? TOOLBAR_METRIC_NAME.GET_VISUAL_MODE_FROM_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.GET_VISUAL_MODE_FROM_COOKIE_ERROR
        );
        return cookieVisualMode;
    } catch {
        return undefined;
    }
};

/**
 * Get toolbar mode field from admin toolbar config first, if not found, get from toolbar settings cookie
 * @returns toolbar mode or undefined if not exist
 */
export const getToolbarModeFromConfigOrCookie = (): ToolbarMode => {
    const toolbarConfiguration = getToolbarConfig();
    if (toolbarConfiguration.toolbarType) {
        return toolbarConfiguration.toolbarType as ToolbarMode;
    }
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieToolbarMode = toolbarSettingsCookie.toolbarMode;
        log.publishCounterMetric(
            cookieToolbarMode
                ? TOOLBAR_METRIC_NAME.GET_TOOLBAR_MODE_FROM_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.GET_TOOLBAR_MODE_FROM_COOKIE_ERROR
        );
        return cookieToolbarMode;
    } catch {
        return undefined;
    }
};

/**
 * Set visual mode field for toolbar settings cookie
 * @param visualMode
 * @returns true if cookie is set successfully, false otherwise
 */
export const setVisualModeForCookie = (visualMode: Mode): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.visualMode = visualMode;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_VISUAL_MODE_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_VISUAL_MODE_IN_COOKIE_ERROR
        );

        return isCookieSet;
    } catch {
        return false;
    }
};

export const setToolbarModeForCookie = (toolbarMode: ToolbarMode): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.toolbarMode = toolbarMode;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_TOOLBAR_MODE_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_TOOLBAR_MODE_IN_COOKIE_ERROR
        );

        return isCookieSet;
    } catch {
        return false;
    }
};

/**
 * Get streaming mode field from toolbar settings cookie
 * @returns streaming mode or undefined if not exist
 */
export const getStreamingModeFromCookie = ():
    | AppStreamEmbedConstant.StreamingMode
    | undefined => {
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieStreamingMode = toolbarSettingsCookie.streamingMode;
        log.publishCounterMetric(
            cookieStreamingMode
                ? TOOLBAR_METRIC_NAME.GET_STREAMING_MODE_FROM_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.GET_DISPLAY_RESOLUTION_FROM_COOKIE_ERROR
        );
        return cookieStreamingMode;
    } catch {
        return undefined;
    }
};

/**
 * Set streaming mode field for toolbar settings cookie
 * @param streamingMode
 * @returns true if cookie is set successfully, false otherwise
 */
export const setStreamingModeForCookie = (
    streamingMode: AppStreamEmbedConstant.StreamingMode
): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.streamingMode = streamingMode;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_STREAMING_MODE_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_STREAMING_MODE_IN_COOKIE_ERROR
        );

        return isCookieSet;
    } catch {
        return false;
    }
};

/**
 * Get display resolution field from toolbar settings cookie
 * If max resolution is set, will fall back to max resolution if display resolution in cookie is greater than max resolution
 * @returns display resolution or undefined if not exist
 */
export const getDisplayResolutionFromCookie = ():
    | AppStreamEmbedConstant.Resolutions
    | undefined => {
    const maxDisplayResolution = getMaxDisplayResolutionFromConfig();
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieDisplayResolution = toolbarSettingsCookie.displayResolution;
        const displayResolution = isDisplayResolutionUnderMaxResolution(
            cookieDisplayResolution,
            maxDisplayResolution
        )
            ? cookieDisplayResolution
            : maxDisplayResolution;
        log.publishCounterMetric(
            displayResolution
                ? TOOLBAR_METRIC_NAME.GET_DISPLAY_RESOLUTION_FROM_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.GET_DISPLAY_RESOLUTION_FROM_COOKIE_ERROR
        );
        return displayResolution;
    } catch {
        return undefined;
    }
};

/**
 * Set display resolution field for toolbar settings cookie
 * @param displayResolution
 * @returns true if cookie is set successfully, false otherwise
 */
export const setDisplayResolutionForCookie = (
    displayResolution: AppStreamEmbedConstant.Resolutions
): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.displayResolution = displayResolution;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_DISPLAY_RESOLUTION_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_DISPLAY_RESOLUTION_IN_COOKIE_ERROR
        );
        return isCookieSet;
    } catch {
        return false;
    }
};

export const getMaxDisplayResolutionFromConfig = (): AppStreamEmbedConstant.Resolutions => {
    const toolbarConfiguration = getToolbarConfig();
    return (
        (toolbarConfiguration.maxDisplayResolution as AppStreamEmbedConstant.Resolutions) ||
        AppStreamEmbedConstant.Resolutions.SIZE_4096_X_2160
    );
};

/**
 * Get showFunctionKeysToolbarItem field from toolbar settings cookie
 * @returns boolean or undefined if not exist
 */
export const getShowFunctionKeysFromCookie = (): boolean | undefined => {
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieShowFunctionKeysToolbarItem =
            toolbarSettingsCookie.showFunctionKeysToolbarItem;
        log.publishCounterMetric(
            cookieShowFunctionKeysToolbarItem !== undefined
                ? TOOLBAR_METRIC_NAME.GET_FUNCTION_KEY_SETTING_FROM_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.GET_FUNCTION_KEY_SETTING_FROM_COOKIE_ERROR
        );
        return cookieShowFunctionKeysToolbarItem;
    } catch {
        return undefined;
    }
};

/**
 * Set showFunctionKeysToolbarItem field for toolbar settings cookie
 * @param shouldShowFunctionKeysToolbarItem (boolean)
 * @returns true if cookie is set successfully, false otherwise
 */
export const setShowFunctionKeysForCookie = (
    shouldShowFunctionKeysToolbarItem: boolean
): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.showFunctionKeysToolbarItem = shouldShowFunctionKeysToolbarItem;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_FUNCTION_KEY_SETTING_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_FUNCTION_KEY_SETTING_IN_COOKIE_ERROR
        );
        return isCookieSet;
    } catch {
        return false;
    }
};

/**
 * Get useOptionKeyAsAltKey field from toolbar settings cookie
 * @returns boolean or undefined if not exist
 */
export const getUseOptionKeyAsAltKeyFromCookie = (): boolean | undefined => {
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieOptionKeySetting =
            toolbarSettingsCookie.useOptionKeyAsAltKey;
        if (cookieOptionKeySetting !== undefined) {
            log.publishCounterMetric(
                cookieOptionKeySetting !== undefined
                    ? TOOLBAR_METRIC_NAME.GET_OPTION_KEY_SETTING_FROM_COOKIE_SUCCESS
                    : TOOLBAR_METRIC_NAME.GET_OPTION_KEY_SETTING_FROM_COOKIE_ERROR
            );
        } else {
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_OPTION_KEY_SETTING_FROM_COOKIE_ERROR
            );
        }
        return cookieOptionKeySetting;
    } catch {
        return undefined;
    }
};

/**
 * Set useOptionKeyAsAltKey field for toolbar settings cookie
 * @param shouldUseOptionKeyAsAltKey (boolean)
 * @returns true if cookie is set successfully, false otherwise
 */
export const setUseOptionKeyAsAltKeyForCookie = (
    shouldUseOptionKeyAsAltKey: boolean
): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.useOptionKeyAsAltKey = shouldUseOptionKeyAsAltKey;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_OPTION_KEY_SETTING_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_OPTION_KEY_SETTING_IN_COOKIE_ERROR
        );
        return isCookieSet;
    } catch {
        return false;
    }
};

/**
 * Get useCommandAsControlKey field from toolbar settings cookie
 * @returns boolean or undefined if not exist
 */
export const getUseCommandAsControlKeyFromCookie = (): boolean | undefined => {
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieCommandKeySetting =
            toolbarSettingsCookie.useCommandAsControlKey;
        log.publishCounterMetric(
            cookieCommandKeySetting !== undefined
                ? TOOLBAR_METRIC_NAME.GET_COMMAND_KEY_SETTING_FROM_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.GET_COMMAND_KEY_SETTING_IN_COOKIE_ERROR
        );
        return cookieCommandKeySetting;
    } catch {
        return undefined;
    }
};

/**
 * Set useCommandAsControlKey field for toolbar settings cookie
 * @param shouldUseCommandAsControlKey (boolean)
 * @returns true if cookie is set successfully, false otherwise
 */
export const setUseCommandAsControlKeyForCookie = (
    shouldUseCommandAsControlKey: boolean
): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.useCommandAsControlKey = shouldUseCommandAsControlKey;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_COMMAND_KEY_SETTING_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_COMMAND_KEY_SETTING_IN_COOKIE_ERROR
        );
        return isCookieSet;
    } catch {
        return false;
    }
};

/**
 * Get toolbarIconLabelsEnabled field from toolbar settings cookie
 * @returns boolean or undefined if not exist
 */
export const getToolbarIconLabelsEnabledFromCookie = ():
    | boolean
    | undefined => {
    try {
        const toolbarSettingsCookie = getToolbarSettingsCookie();
        const cookieToolbarIconLabelSetting =
            toolbarSettingsCookie.toolbarIconLabelsEnabled;
        log.publishCounterMetric(
            cookieToolbarIconLabelSetting == null
                ? TOOLBAR_METRIC_NAME.GET_TOOLBAR_ICON_LABEL_SETTING_FROM_COOKIE_ERROR
                : TOOLBAR_METRIC_NAME.GET_TOOLBAR_ICON_LABEL_SETTING_FROM_COOKIE_SUCCESS
        );
        return cookieToolbarIconLabelSetting;
    } catch {
        return undefined;
    }
};

/**
 * Set toolbarIconLabelsEnabled field for toolbar settings cookie
 * @param shouldEnableToolbarIconLabels (boolean)
 * @returns true if cookie is set successfully, false otherwise
 */
export const setToolbarIconLabelsEnabledForCookie = (
    shouldEnableToolbarIconLabels: boolean
): boolean => {
    let toolbarSettingsCookie: ToolbarSettingsCookie;
    try {
        toolbarSettingsCookie = getToolbarSettingsCookie();
        toolbarSettingsCookie.toolbarIconLabelsEnabled = shouldEnableToolbarIconLabels;
        const isCookieSet = setToolbarSettingsCookie(toolbarSettingsCookie);
        log.publishCounterMetric(
            isCookieSet
                ? TOOLBAR_METRIC_NAME.SET_TOOLBAR_ICON_LABEL_SETTING_IN_COOKIE_SUCCESS
                : TOOLBAR_METRIC_NAME.SET_TOOLBAR_ICON_LABEL_SETTING_IN_COOKIE_ERROR
        );
        return isCookieSet;
    } catch {
        return false;
    }
};

/**
 *
 * @returns hidden toolbar items from admin toolbar config
 */
export const getHiddenToolbarItemsFromConfig = (): string[] => {
    const toolbarConfiguration = getToolbarConfig();
    return toolbarConfiguration.hiddenToolbarItems;
};
